import styles from './Paginations.module.css'
import React from 'react'

const Pagination = ({ postsPerPage, totalPosts, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalPosts / postsPerPage)
  const maxPageNumbersToShow = 4

  // Розрахунок відображуваних номерів сторінок
  let startPage = Math.max(
    1,
    currentPage - Math.floor(maxPageNumbersToShow / 2),
  )
  let endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1)

  // Якщо ми на останніх сторінках, зміщуємо стартову сторінку, щоб залишалося 4 сторінки
  if (endPage - startPage + 1 < maxPageNumbersToShow) {
    startPage = Math.max(1, endPage - maxPageNumbersToShow + 1)
  }

  const pageNumbers = []
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  const handleClick = (e, number) => {
    e.preventDefault()
    paginate(number)
  }

  return (
    <nav className={styles.pagination}>
      <div>
        {/* Кнопка для попередньої сторінки */}
        <a
          href="#!"
          onClick={(e) => {
            e.preventDefault()
            if (currentPage > 1) {
              paginate(currentPage - 1)
            }
          }}
          className={`${styles.prevNext} ${
            currentPage === 1 ? styles.disabled : ''
          }`}
        >
          &lt;
        </a>

        {/* Кнопки для відображуваних сторінок */}
        {pageNumbers.map((number) => (
          <a
            key={number}
            className={currentPage === number ? styles.active : ''}
            href="#!"
            onClick={(e) => handleClick(e, number)}
          >
            {number}
          </a>
        ))}

        {/* Кнопка для наступної сторінки */}
        <a
          href="#!"
          onClick={(e) => {
            e.preventDefault()
            if (currentPage < totalPages) {
              paginate(currentPage + 1)
            }
          }}
          className={`${styles.prevNext} ${
            currentPage === totalPages ? styles.disabled : ''
          }`}
        >
          &gt;
        </a>
      </div>
    </nav>
  )
}

export default Pagination
