import React from 'react'
import style from './Smart.module.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Smart = () => {
  const { t } = useTranslation()
  return (
    <div className={style.smart} id={'smart'}>
      <div className={`${'customContainer'} ${style.smart_wrap}`}>
        <h2 className={style.title}>{t('smart.title')}</h2>
        <p className={style.desc}>{t('smart.desc')}</p>
        <Link to={'/inprogress'} className={style.button}>
          {t('smart.button')}
        </Link>
      </div>
    </div>
  )
}

export default Smart
