import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import style from './LanguagesChanger.module.css'
import { flag_ukraine, flag_usa } from '../../../Assets/Icons/icons'

const languages = [
  {
    language: 'UA',
    img: flag_ukraine,
  },
  {
    language: 'EN',
    img: flag_usa,
  },
]

const LanguagesChanger = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState(languages[0])
  const [isActive, setIsActive] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)

  const changeActive = (active) => {
    setIsActive(active)
    setHasInteracted(true)
  }

  const changeLanguage = (element) => {
    setLanguage(element)
    i18n.changeLanguage(element.language.toLowerCase())
    setIsActive(false)
  }

  return (
    <>
      <div
        className={`${style.backdrop} ${isActive ? style.backdrop_active : ''}`}
        onClick={() => changeActive(false)}
      ></div>
      <div
        className={`${style.wrap} ${hasInteracted ? style.interacted : ''}`}
        onClick={() => changeActive(!isActive)}
        onMouseEnter={() => changeActive(true)}
        onMouseLeave={() => changeActive(false)}
      >
        <div className={style.wrap_box}>
          <img
            src={language.img}
            alt="flag"
            className={style.wrap_box__flag}
            loading={'lazy'}
          />
          <div className={style.wrap_box__lang}>{language.language}</div>
        </div>

        <div
          className={`${style.wrap_add} ${
            isActive ? style.wrap_addActive : style.wrap_addDisable
          }`}
        >
          {languages.map((el) => {
            if (el.language !== language.language) {
              return (
                <div
                  onClick={() => changeLanguage(el)}
                  key={el.language}
                  className={style.wrap_add__box}
                >
                  <img src={el.img} alt="flag" loading={'lazy'} />
                  <div>{el.language}</div>
                </div>
              )
            } else return null
          })}
        </div>
      </div>
    </>
  )
}

export { LanguagesChanger }
