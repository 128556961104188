import React, { useState } from 'react'
import CountUp from 'react-countup'
import { useInView } from 'react-intersection-observer'
import style from './Numbers.module.css'
const Numbers = () => {
  const [startCount, setStartCount] = useState(false)
  const { ref } = useInView({
    triggerOnce: true, // Запустити тільки один раз
    threshold: 0.3, // Відсоток блоку, який має бути у видимій зоні
    onChange: (inView) => {
      if (inView) setStartCount(true)
    },
  })

  return (
    <div className={style.numbers}>
      <div ref={ref} className={`${'customContainer'} ${style.numbers_wrap}`}>
        <div className={style.numbers_item}>
          {startCount && (
            <CountUp
              className={style.numbers_item__number}
              end={38}
              duration={3}
            />
          )}
          <p className={style.numbers_item__text}>Клієнтів</p>
        </div>
        <div className={style.numbers_item}>
          {startCount && (
            <CountUp
              className={style.numbers_item__number}
              end={51}
              duration={3}
            />
          )}
          <p className={style.numbers_item__text}>Реалізованих проєктів</p>
        </div>
        <div className={style.numbers_item}>
          {startCount && (
            <CountUp
              className={style.numbers_item__number}
              end={3}
              duration={3}
            />
          )}
          <p className={style.numbers_item__text}>Країни</p>
        </div>
      </div>
    </div>
  )
}

export default Numbers
