import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import style from './Inprogress.module.css'
const InprogressPage = () => {
  const { t } = useTranslation()

  return (
    <div className={style.inprogress}>
      <div className={`${'customContainer'} ${style.wrap}`}>
        <h2 className={style.title}>{t('inprogress.title')}</h2>
        <p className={style.desc}>
          {t('inprogress.desc1')} <br />
          {t('inprogress.desc2')}
        </p>
        <Link to={'/'} className={style.button}>
          {t('inprogress.button')}
        </Link>
      </div>
    </div>
  )
}

export default InprogressPage
