import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import MainPage from './Pages/MainPage/MainPage'
import Header from './Modules/Header/Header'
import Footer from './Modules/Footer/Footer'
import MissionPage from './Pages/MissionPage/MissionPage'
import InprogressPage from './Pages/InprogressPage/InprogressPage'
import Contact from './Pages/Contacts/Contacts'
import NewsPage from './Pages/NewsPage/NewsPage'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/mission" element={<MissionPage />} />
          <Route path="/inprogress" element={<InprogressPage />} />
          <Route path="/contacts" element={<Contact />} />
          <Route path="/news" element={<NewsPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}

export default App
