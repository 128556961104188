import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import style from './History.module.css'
import {
  History1,
  History2,
  History3,
  History4,
  History5,
  History6,
} from '../../../Assets/Images/MissionPage/History/Histoiry'

const HistorySlider = () => {
  const { t } = useTranslation()
  const [activeSlide, setActiveSlide] = useState(0)
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(3)

  const slides = [
    {
      year: '2013',
      content: t('history.0.content'), // Use translations for content
      img: History1,
    },
    {
      year: '2016',
      content: t('history.1.content'),
      img: History2,
    },
    {
      year: '2018',
      content: t('history.2.content'),
      img: History3,
    },
    {
      year: '2020',
      content: t('history.3.content'),
      img: History4,
    },
    {
      year: '2023',
      content: t('history.4.content'),
      img: History5,
    },
    {
      year: '2024',
      content: t('history.5.content'),
      img: History6,
    },
  ]

  const handleSlideClick = (index) => {
    setActiveSlide(index)
  }

  const updateVisibleSlidesCount = () => {
    if (window.innerWidth <= 768) {
      setVisibleSlidesCount(1)
    } else {
      setVisibleSlidesCount(3)
    }
  }

  useEffect(() => {
    updateVisibleSlidesCount()
    window.addEventListener('resize', updateVisibleSlidesCount)
    return () => {
      window.removeEventListener('resize', updateVisibleSlidesCount)
    }
  }, [])

  const totalSlides = slides.length
  let visibleSlides

  if (visibleSlidesCount === 1) {
    visibleSlides = [slides[activeSlide]]
  } else {
    const startIndex = (activeSlide - 1 + totalSlides) % totalSlides
    const endIndex = (activeSlide + 1) % totalSlides
    visibleSlides = [
      ...slides.slice(startIndex, totalSlides),
      ...slides.slice(0, endIndex + 1),
    ].slice(0, 3)
  }

  return (
    <div className={style.timeline}>
      <div className={style.yearsNavigation}>
        {slides.map((slide, index) => (
          <button
            key={index}
            className={`${style.yearButton} ${
              index === activeSlide ? style.active : ''
            }`}
            onClick={() => handleSlideClick(index)}
          >
            {slide.year}
          </button>
        ))}
      </div>

      <div className={style.slidesContainer}>
        {visibleSlides.map((slide) => (
          <div
            key={slide.year}
            className={`${style.slide} ${
              slide.year === slides[activeSlide].year
                ? style.activeSlide
                : style.inactiveSlide
            }`}
            onClick={() => handleSlideClick(slides.indexOf(slide))}
          >
            <div className={style.slideContent}>
              <div className={style.square1}></div>
              <div className={style.square2}></div>
              <div className={style.square3}></div>
              <div className={style.square4}></div>
              <img className={style.slide_img} src={slide.img} alt="" />
              <h2 className={style.slide_title}>{slide.content}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HistorySlider
