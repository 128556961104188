import React from 'react'
import MainBlock from './MainBlock/MainBlock'
import Numbers from './Numbers/Numbers'
import History from './History/History'
const MissionPage = () => {
  return (
    <div>
      <MainBlock />
      <Numbers />
      <History />
    </div>
  )
}

export default MissionPage
