import React from 'react'
import Engineering from './Engineering/Engineering'
import Smart from './Smart/Smart'
import Complete from './Complete/Complete'

const MainPage = () => {
  return (
    <div>
      <Engineering />
      <Smart />
      <Complete />
    </div>
  )
}

export default MainPage
