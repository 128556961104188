import React from 'react'
import style from './MainBlock.module.css'
import { useTranslation } from 'react-i18next'
import { Link as ScrollLink } from 'react-scroll'

const MainBlock = () => {
  const { t } = useTranslation()
  return (
    <div className={style.main}>
      <div className={`${'customContainer'} ${style.main_wrap}`}>
        <div className={style.text}>
          <h2 className={style.title}>{t('missionMain.title')}</h2>
          <p className={style.desc}>{t('missionMain.desc')}</p>
        </div>
        <ScrollLink to="form" className={style.link}>
          <svg
            className={style.link_icon}
            xmlns="http://www.w3.org/2000/svg"
            width="7"
            height="21"
            viewBox="0 0 7 21"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0 20.6465V0H1V19.4394L6 14.4394L6.70711 15.1465L0.853553 21L0 20.6465Z"
              fill="#98A2B3"
            />
          </svg>
          <p className={style.link_desc}>{t('missionMain.link')}</p>
        </ScrollLink>
      </div>
    </div>
  )
}

export default MainBlock
